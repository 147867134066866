<template>
  <transition name="Transition__fade">
    <div
      v-if="requestsProgress.aging"
      class="DashboardCollections"
    >
      <div class="DashboardCollections__top-section row row--justify-between">
        <div class="column">
          <div class="DashboardCollections__top-section-section column column--justify-between">
            <label class="fc-middle fs-12 uppercase">PERCENT ABOVE 45 DAYS</label>
            <label class="fs-30">{{ percentAbove45 }}%</label>
          </div>
        </div>
        <div class="column column--align-end">
          <div class="DashboardCollections__top-section-section column column--justify-between">
            <label class="fc-middle fs-12 uppercase">
              # OF INVOICES WITH NO PAYMENT DATE ABOVE 45 DAYS
            </label>
            <label class="fs-30">{{ invoicesWithNoPaymentDateAbove45 }}</label>
          </div>
        </div>
      </div>

      <hr class="Divider">

      <base-aging-chart
        v-if="requestsProgress.aging"
        :cat0to30="{ count: aging.cat0to30, percent: aging.cat0to30Percent }"
        :cat31to45="{ count: aging.cat31to45, percent: aging.cat31to45Percent }"
        :cat46to60="{ count: aging.cat46to60, percent: aging.cat46to60Percent }"
        :cat61to90="{ count: aging.cat61to90, percent: aging.cat61to90Percent }"
        :cat91-plus="{ count: aging.cat91Plus, percent: aging.cat91PlusPercent }"
        :cat-total="{ count: aging.catTotal }"
      />
    </div>
  </transition>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import { Invoice } from '../../utils/api'
// Components
import BaseAgingChart from '../../components/base-aging-chart.vue'

export default {
  name: 'DashboardCollections',

  components: {
    BaseAgingChart,
  },

  async created () {
    await this.getCollections()
  },

  data () {
    return {
      aging: {
        cat0to30: null,
        cat0to30Percent: null,
        cat31to45: null,
        cat31to45Percent: null,
        cat46to60: null,
        cat46to60Percent: null,
        cat61to90: null,
        cat61to90Percent: null,
        cat91Plus: null,
        cat91PlusPercent: null,
        catTotal: null,
      },
      invoicesWithNoPaymentDateAbove45: null,
      percentAbove45: null,
      requestsProgress: {
        aging: false,
      },
      targetCollectionsAmt: null,
    }
  },

  methods: {
    async getCat0to30Total () {
      const to = moment().startOf('day').subtract(30, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          gt___approved_date: to,
          status: 'approved'
        })).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get 0 to 30 Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for 0 to 30 total' })
      }
    },

    async getCat31to45Total () {
      const from = moment().startOf('day').subtract(31, 'days').toDate()
      const to = moment().startOf('day').subtract(45, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get 31 to 45 Total"',
          {
            config: error.config,
            from,
            details: error,
            data: this.$data,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for 31 to 45 total' })
      }
    },

    async getCat46to60Total () {
      const from = moment().startOf('day').subtract(46, 'days').toDate()
      const to = moment().startOf('day').subtract(60, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get 46 to 60 Total"',
          {
            config: error.config,
            from,
            details: error,
            data: this.$data,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for 46 to 60 total' })
      }
    },

    async getCat61to90Total () {
      const from = moment().startOf('day').subtract(61, 'days').toDate()
      const to = moment().startOf('day').subtract(90, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          gt___approved_date: to,
          lt___approved_date: from,
          status: 'approved'
        })).data
        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get 61 to 90 Total"',
          {
            config: error.config,
            from,
            details: error,
            data: this.$data,
            props: this.$props,
            response: error.response,
            to,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for 61 to 90 total' })
      }
    },

    async getCat91PlusTotal () {
      const from = moment().startOf('day').subtract(91, 'days').toDate()
      let result
      try {
        result = (await Invoice.queryList({
          lt___approved_date: from,
          status: 'approved'
        })).data

        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get 91 Plus Total"',
          {
            config: error.config,
            from,
            details: error,
            data: this.$data,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for 91 Plus total' })
      }
    },

    async getCatTotal () {
      let result
      try {
        result = (await Invoice.queryList({
          status: 'approved'
        }, 1)).data
        return result.facets_stats ? (result.facets_stats.total_amount.sum / 100) : 0
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Get Total"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue search for invoices for total' })
      }
    },

    async getCollections () {
      this.progressStart()

      try {
        // DIVIDE approved invoice ammounts into aging categories and total each category
        // 'cat' is short for 'category'
        const cat0to30Total = await this.getCat0to30Total()
        const cat31to45Total = await this.getCat31to45Total()
        const cat46to60Total = await this.getCat46to60Total()
        const cat61to90Total = await this.getCat61to90Total()
        const cat91PlusTotal = await this.getCat91PlusTotal()
        const catTotal = await this.getCatTotal()

        // Do NOT .toFixed(2) here; keeps calculating the percentAbove45 as accurate as possible
        const cat0to30Percent = (cat0to30Total / catTotal * 100)
        const cat31to45Percent = (cat31to45Total / catTotal * 100)
        const cat46to60Percent = (cat46to60Total / catTotal * 100)
        const cat61to90Percent = (cat61to90Total / catTotal * 100)
        const cat91PlusPercent = (cat91PlusTotal / catTotal * 100)

        this.$set(this.aging, 'cat0to30', cat0to30Total)
        this.$set(this.aging, 'cat0to30Percent', isNaN(cat0to30Percent) ? 0 : cat0to30Percent.toFixed(2))
        this.$set(this.aging, 'cat31to45', cat31to45Total)
        this.$set(this.aging, 'cat31to45Percent', isNaN(cat31to45Percent) ? 0 : cat31to45Percent.toFixed(2))
        this.$set(this.aging, 'cat46to60', cat46to60Total)
        this.$set(this.aging, 'cat46to60Percent', isNaN(cat46to60Percent) ? 0 : cat46to60Percent.toFixed(2))
        this.$set(this.aging, 'cat61to90', cat61to90Total)
        this.$set(this.aging, 'cat61to90Percent', isNaN(cat61to90Percent) ? 0 : cat61to90Percent.toFixed(2))
        this.$set(this.aging, 'cat91Plus', cat91PlusTotal)
        this.$set(this.aging, 'cat91PlusPercent', isNaN(cat91PlusPercent) ? 0 : cat91PlusPercent.toFixed(2))
        this.$set(this.aging, 'catTotal', catTotal)

        this.invoicesWithNoPaymentDateAbove45 = await this.getInvoicesWithNoPaymentDateAbove45()
        this.percentAbove45 = (cat46to60Percent + cat61to90Percent + cat91PlusPercent).toFixed(2)

        this.$set(this.requestsProgress, 'aging', true)

        this.progressFinish()
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the collections data' })
      }
    },

    async getInvoicesWithNoPaymentDateAbove45 () {
      const from = moment().startOf('day').subtract(45, 'days').toDate()
      try {
        return (await Invoice.queryList({
          lt___approved_date: from,
          status: 'approved'
        })).data.count
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Collections "Invoices With No Payment Date Above 45"',
          {
            config: error.config,
            from,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue search for invoices with no payment date past 45 days'
        })
      }
    },
  },
}
</script>

<style lang="sass">
.DashboardCollections

  &__top-section
    margin-bottom: rem(62px)
    margin-top: rem(6px) // + the 36padding for the router-view of the dashboard-collections view

    & > .column
      border-right: $border

      &:last-child
        border-right: none

      &:nth-child(1)
        padding-right: rem(99px)
      &:nth-child(2)
        padding-left: rem(99px)
        padding-right: rem(99px)
      &:nth-child(3)
        padding-left: rem(99px)

  &__top-section-section
    height: rem(99px)
    width: rem(210px)
</style>