var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "Transition__fade" } }, [
    _vm.requestsProgress.aging
      ? _c(
          "div",
          { staticClass: "DashboardCollections" },
          [
            _c(
              "div",
              {
                staticClass:
                  "DashboardCollections__top-section row row--justify-between"
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "DashboardCollections__top-section-section column column--justify-between"
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "fc-middle fs-12 uppercase" },
                        [_vm._v("PERCENT ABOVE 45 DAYS")]
                      ),
                      _c("label", { staticClass: "fs-30" }, [
                        _vm._v(_vm._s(_vm.percentAbove45) + "%")
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "column column--align-end" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "DashboardCollections__top-section-section column column--justify-between"
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "fc-middle fs-12 uppercase" },
                        [
                          _vm._v(
                            "\n            # OF INVOICES WITH NO PAYMENT DATE ABOVE 45 DAYS\n          "
                          )
                        ]
                      ),
                      _c("label", { staticClass: "fs-30" }, [
                        _vm._v(_vm._s(_vm.invoicesWithNoPaymentDateAbove45))
                      ])
                    ]
                  )
                ])
              ]
            ),
            _c("hr", { staticClass: "Divider" }),
            _vm.requestsProgress.aging
              ? _c("base-aging-chart", {
                  attrs: {
                    cat0to30: {
                      count: _vm.aging.cat0to30,
                      percent: _vm.aging.cat0to30Percent
                    },
                    cat31to45: {
                      count: _vm.aging.cat31to45,
                      percent: _vm.aging.cat31to45Percent
                    },
                    cat46to60: {
                      count: _vm.aging.cat46to60,
                      percent: _vm.aging.cat46to60Percent
                    },
                    cat61to90: {
                      count: _vm.aging.cat61to90,
                      percent: _vm.aging.cat61to90Percent
                    },
                    "cat91-plus": {
                      count: _vm.aging.cat91Plus,
                      percent: _vm.aging.cat91PlusPercent
                    },
                    "cat-total": { count: _vm.aging.catTotal }
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }